import React from "react"

import { Arrow, IconPrevious } from "./SearchPaginationStyles"

interface Props {
  disabled: boolean
  onClick: any
}

export const SearchPaginationPrevious = ({ disabled, onClick }: Props) => (
  <Arrow disabled={disabled} onClick={onClick}>
    <IconPrevious />
  </Arrow>
)
