import styled from "@emotion/styled"
import tw from "twin.macro"

import Previous from "static/icons/theme/previous.svg"
import Next from "static/icons/theme/next.svg"

export const IconPrevious = tw(Previous)`block w-1-6 h-1-6`
export const IconNext = tw(Next)`block w-1-6 h-1-6`

export const Pagination = tw.nav`flex items-center justify-center md:justify-end pt-12`
export const Page = styled.button`
  ${tw`text-center text-sm tracking-wide leading-normal min-w-4-6 pt-1 border-t whitespace-no-wrap focus:outline-none transition-all duration-200 duration-100`}
  ${props => (props.active ? tw`text-dark border-dark` : tw`text-grey-mid hover:text-grey-darker border-grey-DEFAULT hover:border-grey-darker`)}
`
export const Arrow = styled(Page)`
  ${tw`border-t-0 flex items-center justify-center transition-all duration-200 duration-100`}
  ${props => (props.disabled ? tw`invisible opacity-0` : tw`visible opacity-100`)}
`
