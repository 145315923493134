import React from "react"
import { SearchContainer, SearchInner, SearchLabel } from "../SearchStyles"
import { SearchBar } from "@app/components/Navigation/Search/Form/SearchBar"

export const SearchForm = () => {
  return (
    <SearchContainer>
      <SearchInner>
        <SearchLabel>{`Search:`}</SearchLabel>
        <SearchBar placeholder={"What are you looking for?"} />
      </SearchInner>
    </SearchContainer>
  )
}
