import React from "react"

import { Arrow, IconNext } from "./SearchPaginationStyles"

interface Props {
  disabled: boolean
  onClick: any
}

export const SearchPaginationNext = ({ disabled, onClick }: Props) => (
  <Arrow disabled={disabled} onClick={onClick}>
    <IconNext />
  </Arrow>
)
