import React from "react"

import { withSearchPagination } from "./withSearchPagination"

import { SearchPaginationPrevious } from "./SearchPaginationPrevious"
import { SearchPaginationNext } from "./SearchPaginationNext"
import { Pagination, Page } from "./SearchPaginationStyles"
import { StyledContainer } from "../SearchStyles"

interface Props {
  currentPage: number
  current: number
  handleChangePage: any
  hasNextPage: any
  hasPreviousPage: any
  pages: Array<number>
}

export const SearchPagination = withSearchPagination(({ currentPage, current, handleChangePage, hasNextPage, hasPreviousPage, pages }: Props) => (
  <Pagination>
    <SearchPaginationPrevious disabled={!hasPreviousPage} onClick={() => handleChangePage(currentPage - 1)} />

    {pages.map(page => (
      <Page key={page} active={current === page} onClick={event => handleChangePage(page - 1, event)}>
        {page}
      </Page>
    ))}

    <SearchPaginationNext disabled={!hasNextPage} onClick={() => handleChangePage(currentPage + 1)} />
  </Pagination>
))
