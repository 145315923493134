import React from "react"

import { useCore } from "@hooks/useCore"

export const withSearchPagination = Component => props => {
  const { name = `SearchPagination`, currentPage, totalPages, handleNextPage, handlePreviousPage, handlePageChange } = props
  const {
    helpers: { isBrowser },
  } = useCore()

  const buildPages = (current, total, max) => {
    const iterations = max > total ? total : max
    const average = Math.floor(iterations / 2)
    const upper = total - average
    const start =
      current > average && current <= upper
        ? current - average
        : current > upper && iterations < total
        ? total - iterations + Math.floor(average / 2)
        : 1
    const pages = []

    for (var index = 0; index < iterations; index++) {
      const page = start + index
      if (page <= total) {
        pages.push(page)
      }
    }

    return pages
  }

  const current = currentPage + 1
  const hasPreviousPage = current > 1
  const hasNextPage = current < totalPages
  const pages = buildPages(current, totalPages, 6)

  const handleChangePage = (page, event) => {
    handlePageChange(page, event)

    if (isBrowser) {
      window.scrollTo({
        top: 0,
        behavior: `smooth`,
      })
    }
  }

  Component.displayName = name
  return (
    <Component
      currentPage={currentPage}
      current={current}
      handleChangePage={handleChangePage}
      hasNextPage={hasNextPage}
      hasPreviousPage={hasPreviousPage}
      pages={pages}
    />
  )
}
