import React from "react"

// import { withSearchResults } from "./withSearchResults"
import { SearchItem } from "../Item/SearchItem"
// import { StyledContainer, ResultsRow, ResultsProducts } from "../SearchStyles"
import { useShopify } from "@app/hooks/useShopify"
import { Results } from "@usereactify/search"
import { SearchPagination } from "../Pagination/SearchPagination"

interface Props {
  collectionOption: any
  collection: {
    title: string
    handle: string
  }
}

export const SearchResults: React.FC<Props> = ({ collectionOption, collection }) => {
  const { adminProductNormaliser } = useShopify()
  return (
    <Results
      listClassName="search-results"
      renderError={() => <div />}
      renderLoading={() => <div />}
      renderNoResults={() => <div />}
      renderResultCardProduct={renderProps => {
        return (
          <SearchItem
            key={renderProps.product.id}
            collection={collection}
            list={collection?.title || `Search`}
            product={adminProductNormaliser(renderProps.product)}
            verticalSpacing
            hoverImageIndex={collectionOption?.hoverImageIndex}
          />
        )
      }}
      renderPaginationNumbered={renderProps => <SearchPagination {...renderProps} />}
    />
  )
}
