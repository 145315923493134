import React, { useCallback } from "react"
import { ReactifySearchProvider, Sensors } from "@usereactify/search"
import { withSearch } from "./withSearch"
import { SearchForm } from "./Form/SearchForm"
// import { SearchLoading } from "./Loading/SearchLoading"
// import { SearchPagination } from "./Pagination/SearchPagination"
// import { SearchResults } from "./Results/SearchResults"
// import { SearchEmpty } from "./Results/SearchEmpty"
// import { SearchSensorHidden } from "./Sensors/SearchSensorHidden"
// import { SearchSensorPublished } from "./Sensors/SearchSensorPublished"
// import { SearchComponents } from "@hooks/useSearch"

import { SearchPageContainer, SearchWrapper } from "./SearchStyles"
import { SearchResults } from "./Results/SearchResults"
import { navigate } from "gatsby"

export const Search = withSearch(({ shopDomain, hoverImageIndex }) => {
  const handleRedirect = useCallback((type, url) => ["search", "redirect"].includes(type) && navigate(url), [])
  return (
    <ReactifySearchProvider mode="search" onRedirect={handleRedirect} shopifyPermanentDomain={shopDomain}>
      <SearchPageContainer width={`lg`}>
        <SearchWrapper>
          <SearchForm />
          <div style={{ display: "none" }}>
            <Sensors />
          </div>
          <SearchResults collectionOption={{ hoverImageIndex }} />
          {/* <SearchSensorHidden /> */}
          {/* <SearchSensorPublished /> */}
          {/* <SearchComponents.ReactiveList
          {...resultsConfig}
          renderNoResults={() => <SearchEmpty locales={locales} products={products} search={true} />}
          render={({ data, loading }) =>
            loading ? (
              <SearchLoading pagination={resultsConfig?.size} />
            ) : (
              <SearchResults
                results={data}
                collectionOption={{
                  hoverImageIndex,
                }}
              />
            )
          }
          renderPagination={pagination => <SearchPagination {...pagination} />}
        /> */}
        </SearchWrapper>
      </SearchPageContainer>
    </ReactifySearchProvider>
  )
})
